<template>
    <el-table :data="clients" v-loading="$waiting.is('loading')" class="w-full">
        <el-table-column prop="number" label="Nr" width="90" sortable />
        <el-table-column prop="name" label="Företag" sortable />
        <el-table-column prop="status" label="Status" sortable />
        <el-table-column prop="agent" label="Ombud" />
        <el-table-column prop="updated" label="Uppdaterad" sortable />
        <el-table-column prop="balanceBailiff" label="Saldo Kronofogden" align="right" sortable :sort-method="sortableMethodBailiff" width="170">
            <template slot-scope="scope">
                {{ scope.row.balanceBailiff | swedishNumberFormat }}
            </template>
        </el-table-column>
        <el-table-column prop="balanceSkv" label="Saldo Skatteverket" align="right" sortable :sort-method="sortableMethodSkv" width="170">
            <template slot-scope="scope">
                {{ scope.row.balanceSkv | swedishNumberFormat }}
            </template>
        </el-table-column>
    </el-table>
</template>

<script>
export default {
    props: {
        clients: {
            type: Array,
            default: () => [],
        },
    },

    methods: {
        sortableMethodBailiff(a, b) {
            return a.balanceBailiff > b.balanceBailiff ? 1 : -1;
        },
        sortableMethodSkv(a, b) {
            return a.balanceSkv > b.balanceSkv ? 1 : -1;
        },
    },
};
</script>
