<template>
    <ClientsTable :clients="clients" />
</template>
<script>
import Api from "../taxAccount.api";
import ClientsTable from "./ui/ClientsTable";

export default {
    data: function() {
        return {
            clients: [],
        };
    },

    components: {
        ClientsTable,
    },

    watch: {
        isGlobalClientSelected: {
            immediate: true,
            async handler() {
                this.$waiting.start("loading");
                if (!this.isGlobalClientSelected) {
                    this.clients = await Api.getAccountsList();
                } else {
                    this.clients = await Api.getClientAccounts(this.$store.state.topBar.selectedClient.value);
                }
                this.$waiting.end("loading");
            },
        },
    },

    async created() {
        document.title = "Skattekonto - Lista";
    },

    computed: {
        isGlobalClientSelected() {
            return this.$store.getters["topBar/isClientSelected"];
        },
    },
};
</script>
